import React, { FunctionComponent } from 'react';
import { SlideShowSlideProps } from '../SlideShowSlide.types';
import MeshContainer from '../../../thunderbolt-core-components/MeshContainer/viewer/MeshContainer';
import FillLayers from '../../FillLayers/viewer/FillLayers';
import styles from './style/SlideShowSlide.scss';

const SlideShowSlide: FunctionComponent<SlideShowSlideProps> = ({
  id,
  fillLayers,
  children,
  skin,
  meshProps,
  onMouseEnter,
  onMouseLeave,
  getPlaceholder,
}) => {
  const MeshContainerWithChildren = () => (
    <MeshContainer id={id} {...meshProps}>
      {children}
    </MeshContainer>
  );

  return (
    <div
      id={id}
      className={styles.SlideShowSlide}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <FillLayers
        {...fillLayers}
        extraClass={styles.bg}
        getPlaceholder={getPlaceholder}
      />
      <div className={styles.borderNode} />
      {skin === 'StateBoxStateFormSkin' ? (
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <MeshContainerWithChildren />
        </form>
      ) : (
        <MeshContainerWithChildren />
      )}
    </div>
  );
};

export default SlideShowSlide;
